// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-category-cabinetry-index-js": () => import("./../../../src/components/Category/Cabinetry/index.js" /* webpackChunkName: "component---src-components-category-cabinetry-index-js" */),
  "component---src-components-category-cabinetry-list-js": () => import("./../../../src/components/Category/Cabinetry/list.js" /* webpackChunkName: "component---src-components-category-cabinetry-list-js" */),
  "component---src-components-category-facade-index-js": () => import("./../../../src/components/Category/Facade/index.js" /* webpackChunkName: "component---src-components-category-facade-index-js" */),
  "component---src-components-category-facade-list-js": () => import("./../../../src/components/Category/Facade/list.js" /* webpackChunkName: "component---src-components-category-facade-list-js" */),
  "component---src-components-category-finishes-fixtures-index-js": () => import("./../../../src/components/Category/FinishesFixtures/index.js" /* webpackChunkName: "component---src-components-category-finishes-fixtures-index-js" */),
  "component---src-components-category-finishes-fixtures-list-js": () => import("./../../../src/components/Category/FinishesFixtures/list.js" /* webpackChunkName: "component---src-components-category-finishes-fixtures-list-js" */),
  "component---src-components-category-furniture-index-js": () => import("./../../../src/components/Category/Furniture/index.js" /* webpackChunkName: "component---src-components-category-furniture-index-js" */),
  "component---src-components-category-furniture-list-js": () => import("./../../../src/components/Category/Furniture/list.js" /* webpackChunkName: "component---src-components-category-furniture-list-js" */),
  "component---src-components-category-home-design-index-js": () => import("./../../../src/components/Category/HomeDesign/index.js" /* webpackChunkName: "component---src-components-category-home-design-index-js" */),
  "component---src-components-category-home-design-list-js": () => import("./../../../src/components/Category/HomeDesign/list.js" /* webpackChunkName: "component---src-components-category-home-design-list-js" */),
  "component---src-components-category-land-index-js": () => import("./../../../src/components/Category/Land/index.js" /* webpackChunkName: "component---src-components-category-land-index-js" */),
  "component---src-components-category-land-list-js": () => import("./../../../src/components/Category/Land/list.js" /* webpackChunkName: "component---src-components-category-land-list-js" */),
  "component---src-components-category-selection-index-js": () => import("./../../../src/components/Category/Selection/index.js" /* webpackChunkName: "component---src-components-category-selection-index-js" */),
  "component---src-components-category-selection-list-js": () => import("./../../../src/components/Category/Selection/list.js" /* webpackChunkName: "component---src-components-category-selection-list-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-categories-js": () => import("./../../../src/pages/blog-categories.js" /* webpackChunkName: "component---src-pages-blog-categories-js" */),
  "component---src-pages-board-detail-js": () => import("./../../../src/pages/board-detail.js" /* webpackChunkName: "component---src-pages-board-detail-js" */),
  "component---src-pages-browser-home-design-cate-js": () => import("./../../../src/pages/browser-home-design-cate.js" /* webpackChunkName: "component---src-pages-browser-home-design-cate-js" */),
  "component---src-pages-browser-land-js": () => import("./../../../src/pages/browser-land.js" /* webpackChunkName: "component---src-pages-browser-land-js" */),
  "component---src-pages-cabinetry-detail-js": () => import("./../../../src/pages/cabinetry-detail.js" /* webpackChunkName: "component---src-pages-cabinetry-detail-js" */),
  "component---src-pages-create-account-js": () => import("./../../../src/pages/create-account.js" /* webpackChunkName: "component---src-pages-create-account-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-explore-archived-js": () => import("./../../../src/pages/explore_archived.js" /* webpackChunkName: "component---src-pages-explore-archived-js" */),
  "component---src-pages-facade-js": () => import("./../../../src/pages/facade.js" /* webpackChunkName: "component---src-pages-facade-js" */),
  "component---src-pages-finishes-detail-js": () => import("./../../../src/pages/finishes-detail.js" /* webpackChunkName: "component---src-pages-finishes-detail-js" */),
  "component---src-pages-home-design-detail-js": () => import("./../../../src/pages/home-design-detail.js" /* webpackChunkName: "component---src-pages-home-design-detail-js" */),
  "component---src-pages-landing-detail-js": () => import("./../../../src/pages/landing-detail.js" /* webpackChunkName: "component---src-pages-landing-detail-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-address-js": () => import("./../../../src/pages/my-address.js" /* webpackChunkName: "component---src-pages-my-address-js" */),
  "component---src-pages-my-board-category-js": () => import("./../../../src/pages/my-board-category.js" /* webpackChunkName: "component---src-pages-my-board-category-js" */),
  "component---src-pages-my-board-js": () => import("./../../../src/pages/my-board.js" /* webpackChunkName: "component---src-pages-my-board-js" */),
  "component---src-pages-my-board-view-all-js": () => import("./../../../src/pages/my-board-view-all.js" /* webpackChunkName: "component---src-pages-my-board-view-all-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-style-detail-js": () => import("./../../../src/pages/style-detail.js" /* webpackChunkName: "component---src-pages-style-detail-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-layout-account-page-js": () => import("./../../../src/templates/Layout/AccountPage.js" /* webpackChunkName: "component---src-templates-layout-account-page-js" */),
  "component---src-templates-layout-blog-holder-js": () => import("./../../../src/templates/Layout/BlogHolder.js" /* webpackChunkName: "component---src-templates-layout-blog-holder-js" */),
  "component---src-templates-layout-blog-post-js": () => import("./../../../src/templates/Layout/BlogPost.js" /* webpackChunkName: "component---src-templates-layout-blog-post-js" */),
  "component---src-templates-layout-board-detail-page-js": () => import("./../../../src/templates/Layout/BoardDetailPage.js" /* webpackChunkName: "component---src-templates-layout-board-detail-page-js" */),
  "component---src-templates-layout-elemental-page-js": () => import("./../../../src/templates/Layout/ElementalPage.js" /* webpackChunkName: "component---src-templates-layout-elemental-page-js" */),
  "component---src-templates-layout-explore-all-products-js": () => import("./../../../src/templates/Layout/ExploreAllProducts.js" /* webpackChunkName: "component---src-templates-layout-explore-all-products-js" */),
  "component---src-templates-layout-home-design-page-js": () => import("./../../../src/templates/Layout/HomeDesignPage.js" /* webpackChunkName: "component---src-templates-layout-home-design-page-js" */),
  "component---src-templates-layout-home-page-js": () => import("./../../../src/templates/Layout/HomePage.js" /* webpackChunkName: "component---src-templates-layout-home-page-js" */),
  "component---src-templates-layout-login-page-js": () => import("./../../../src/templates/Layout/LoginPage.js" /* webpackChunkName: "component---src-templates-layout-login-page-js" */),
  "component---src-templates-layout-my-boards-page-js": () => import("./../../../src/templates/Layout/MyBoardsPage.js" /* webpackChunkName: "component---src-templates-layout-my-boards-page-js" */),
  "component---src-templates-layout-page-js": () => import("./../../../src/templates/Layout/Page.js" /* webpackChunkName: "component---src-templates-layout-page-js" */),
  "component---src-templates-layout-register-page-js": () => import("./../../../src/templates/Layout/RegisterPage.js" /* webpackChunkName: "component---src-templates-layout-register-page-js" */),
  "component---src-templates-layout-search-results-page-js": () => import("./../../../src/templates/Layout/SearchResultsPage.js" /* webpackChunkName: "component---src-templates-layout-search-results-page-js" */),
  "component---src-templates-layout-user-defined-form-js": () => import("./../../../src/templates/Layout/UserDefinedForm.js" /* webpackChunkName: "component---src-templates-layout-user-defined-form-js" */)
}

