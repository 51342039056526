const active = []

if (typeof window !== 'undefined') {
  const currentParams = new URLSearchParams(window.location.search)
  for (const param of currentParams.entries()) {
    const value = param[1].split('_').pop()
    if (!value) continue
    active.push({
      type: param[0],
      label: param[1].replace(`_${value}`, ''),
      value: parseInt(value, 10),
    })
  }
}

const initialState = {
  active
}

const ADD_FILTER = 'ADD_FILTER'
const REMOVE_FILTER = 'REMOVE_FILTER'
const TOGGLE_FILTER = 'TOGGLE_FILTER'
const RESET_FILTER = 'RESET_FILTER'

export const addFilter = filter => ({
  type: ADD_FILTER, filter
})

export const removeFilter = filter => ({
  type: REMOVE_FILTER, filter
})

export const toggleFilter = filter => ({
  type: TOGGLE_FILTER, filter
})

export const resetFilter = () => ({
  type: RESET_FILTER
})

const addSearchParam = filter => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    const { type, label, value } = filter
    const currentParamValues = urlParams.getAll(type)
    if (!currentParamValues.includes(`${label}_${value}`)) urlParams.append(type, `${label}_${value}`)
    window.history.replaceState(null, null, '?' + urlParams.toString())
  }
}

const removeSearchParam = filter => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    const { type, label, value } = filter
    const currentParamValues = urlParams.getAll(type)
    if (currentParamValues.includes(`${label}_${value}`)) {
      urlParams.delete(type)
      for (const val of currentParamValues.filter(cpv => cpv != `${label}_${value}`)) {
        urlParams.append(type, val)
      }
    }
    window.history.replaceState(null, null, '?' + urlParams.toString())
  }
}

const removeAllParams = () => {
  window.history.replaceState(null, null, '?')
}

export default (state = initialState, action) => {
  let baseFilters = null
  switch (action.type) {
    case ADD_FILTER:
      baseFilters = [...state.active]
      if (!baseFilters.some(f => f.type === action.filter.type && f.value === action.filter.value)) baseFilters.push(action.filter)
      addSearchParam(action.filter)
      return { ...state, active: baseFilters }
    case REMOVE_FILTER:
      baseFilters = [...state.active]
      removeSearchParam(action.filter)
      return { ...state, active: baseFilters.filter(f => f.type !== action.filter.type || f.value !== action.filter.value) }
    case TOGGLE_FILTER:
      baseFilters = [...state.active]
      if (!baseFilters.some(f => f.type === action.filter.type && f.value === action.filter.value)) {
        addSearchParam(action.filter)
        baseFilters.push(action.filter)
      } else {
        removeSearchParam(action.filter)
        baseFilters = baseFilters.filter(f => f.type !== action.filter.type || f.value !== action.filter.value)
      }

      return { ...state, active: baseFilters }
    case RESET_FILTER:
      removeAllParams()
      return { ...state, active: [] }
    default:
      return state
  }
}