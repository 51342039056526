import { getUser, isLoggedIn } from '../services/auth'

const initialState = {
  all: []
}

const GET_BOARD_FROM_LOCAL_STORAGE = 'GET_BOARD_FROM_LOCAL_STORAGE'
const UPDATE_BOARD = 'UPDATE_BOARD'

export const getBoardFromLocalStorage = () => ({
  type: GET_BOARD_FROM_LOCAL_STORAGE
})

export const updateBoard = boards => ({
  type: UPDATE_BOARD,
  boards
})

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOARD_FROM_LOCAL_STORAGE:
      const Member = getUser()
      let boards = []

      if (isLoggedIn()) {
        boards = Object.keys(Member.Boards).map(key => Member.Boards[key])
      }
      return { ...state, all: boards }
    case UPDATE_BOARD:
      console.log(action.boards)
      return { ...state, all: JSON.parse(JSON.stringify(action.boards)) }
    default:
      return state
  }
}